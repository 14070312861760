import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import definition from "@/game-eft/definition.mjs";
import getProfileAvatar from "@/game-eft/utils/getProfileAvatar.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import isEmpty from "@/util/is-empty.mjs";
import isObject from "@/util/is-object.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function AccountsEFT() {
  const state = useSnapshot(readState);
  const eft = state.settings.loggedInAccounts[definition.shortName] as Record<
    string,
    {
      accountId: string;
      id: string;
      nickname: string;
    }
  >;
  const profiles = useMemo(
    () =>
      Object.entries(eft ?? {})
        .filter(([_, profile]) => isObject(profile) && !isEmpty(profile))
        .map(([, profile]) => ({
          displayName: profile.nickname,
          key: profile.accountId,
          avatar: getProfileAvatar(),
        })),
    [eft],
  );
  return (
    <Accounts
      profiles={profiles}
      gameShort={definition.shortName}
      autoDetectText={[
        "eft:settings.autoDetectProfile",
        "Hey! I can automatically detect your profile. Just open Escape From Tarkov and log into the account you want to connect.",
      ]}
      renderOtherActionItems={undefined}
      renderOtherItems={undefined}
    />
  );
}
